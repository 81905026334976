import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Accueil | Bienvenue chez Gallic
			</title>
			<meta name={"description"} content={"Là où votre voyage commence par une chaleureuse étreinte de solutions automobiles fiables. "} />
			<meta property={"og:title"} content={"Accueil | Bienvenue chez Gallic"} />
			<meta property={"og:description"} content={"Là où votre voyage commence par une chaleureuse étreinte de solutions automobiles fiables. "} />
			<meta property={"og:image"} content={"https://gallic.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://gallic.live/media/523523522535.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://gallic.live/media/523523522535.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://gallic.live/media/523523522535.png"} />
			<meta name={"msapplication-TileImage"} content={"https://gallic.live/media/523523522535.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Bienvenue chez Gallic
				</Text>
				<Text
					margin="0px 0px 30px 0px"
					font="normal 400 22px/1.1 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 80px 0px 80px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Là où votre voyage commence par une chaleureuse étreinte de solutions automobiles fiables. Chez Gallic, nous sommes fiers d'être plus qu'un simple service automobile - nous sommes votre partenaire de confiance sur la route. Notre atmosphère accueillante, combinée à un engagement de service exceptionnel, garantit que chaque visite chez Gallic est un pas vers la performance ininterrompue de votre cher véhicule. Découvrez la différence Gallic, où les soins automobiles rencontrent un dévouement à votre satisfaction, faisant de nous votre destination de choix pour tout ce qui touche à l'automobile.
				</Text>
				<Button
					margin="0px 0 50px 0px"
					padding="14px 35px 14px 35px"
					background="--color-primary"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="--color-primary"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					border-radius="5px"
				>
					Contactez-nous
				</Button>
			</Box>
			<Image
				src="https://gallic.live/media/1.jpg"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://gallic.live/media/2.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Découvrez la différence Gallic
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Chez Gallic, nous comprenons l'importance d'un véhicule fiable et en bon état. Notre équipe de mécaniciens chevronnés se consacre à fournir des services automobiles exceptionnels adaptés à vos besoins. Qu'il s'agisse d'entretien courant, de réparations ou de vérifications diagnostiques, votre satisfaction est notre priorité.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Pourquoi choisir Gallic
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Des techniciens expérimentés
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Nos mécaniciens qualifiés apportent des années d'expérience et d'expertise à chaque travail, garantissant que votre véhicule est entre de bonnes mains.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Un service transparent{" "}
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Nous croyons en l'honnêteté et en la transparence. Avant toute intervention, nous vous fournissons une explication détaillée des travaux à effectuer, afin que vous soyez informé à chaque étape du processus.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Délai d'exécution rapide
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Nous accordons de l'importance à votre temps. Notre service efficace vise des délais d'exécution rapides sans compromis sur la qualité.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Des prix équitables
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Profitez de prix compétitifs et justes pour tous nos services, sans frais cachés ni surenchères inutiles.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Entretien courant{" "}
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Maintenez votre voiture en parfait état grâce à des contrôles réguliers, des vidanges et des remplissages de liquides.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Réparations
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Des problèmes de moteur aux problèmes de freins, notre équipe est équipée pour prendre en charge un large éventail de réparations.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Services de diagnostic{" "}
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Nos outils de diagnostic avancés permettent d'identifier et de résoudre les problèmes avec précision.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Services de pneus
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Assurez la performance optimale de vos pneus grâce à des services tels que la rotation, le réglage de la géométrie et le remplacement des pneus.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Offres de services
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="150px 0 150px 0"
			min-height="100vh"
			background="linear-gradient(180deg,--color-light 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://gallic.live/media/3.jpg) 0% 0% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline2"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
				>
					Gallic
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
				>
					La précision et la performance au service de la sérénité automobile
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});